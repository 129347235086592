import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  loadFields(event) {
    const country = event.target.value;
    fetch(`/country_fields`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({ country: country }),
    })
      .then((response) => response.text())
      .then((html) => {
        document.getElementById("address-fields").innerHTML = html;
      })
      .catch((error) => console.error("Error:", error));
  }
}
