import { Controller } from "@hotwired/stimulus";
import { refreshVMasker } from "../src/admin/initializers/vmasker";

// Connects to data-controller="registration"
export default class extends Controller {
  static targets = [
    "label0",
    "label1",
    "label2",
    "label3",
    "label4",
    "label5",
    "label6",
    "form1",
    "form2",
    "form3",
    "form4",
    "form5",
    "form6",
    "form7",
    "hasRate",
    "hasRatePagarMe",
    "additionalFields",
    "documentLabel",
    "documentField",
  ];

  initialize() {
    let form1 = document.querySelector("#form_ciclopay");
    let form2 = document.querySelector("#form_abmex");
    let form3 = document.querySelector("#form_ultragate");
    let form4 = document.querySelector("#form_pagar_me");
    let form5 = document.querySelector("#form_asaas");
    let form6 = document.querySelector("#form_cashtime");
    let form7 = document.querySelector("#form_cashtime_pay");
    if (form1) {
      this.showAdditionalFields0();
    } else if (form2) {
      this.showAdditionalFields1();
    } else if (form3) {
      this.showAdditionalFields2();
    } else if (form4) {
      this.showAdditionalFields3();
    } else if (form5) {
      this.showAdditionalFields4();
    } else if (form6) {
      this.showAdditionalFields5();
    } else if (form7) {
      this.showAdditionalFields6();
    }
    let labels = document.querySelectorAll(".form-check-label");
    for (let label of labels) {
      label.classList.add("d-none");
    }
  }

  showRateField(e) {
    if (e.target.value == "true") {
      console.log(this.hasRateTarget);
      this.hasRateTarget.classList.remove("d-none");
    } else {
      this.hasRateTarget.classList.add("d-none");
    }
  }

  showRateFieldPagarMe(e) {
    if (e.target.value == "true") {
      this.hasRatePagarMeTarget.classList.remove("d-none");
    } else {
      this.hasRatePagarMeTarget.classList.add("d-none");
    }
  }

  showAdditionalFields0() {
    let form2 = document.querySelector("#form_abmex");
    let form3 = document.querySelector("#form_ultragate");
    let form4 = document.querySelector("#form_pagar_me");
    let form5 = document.querySelector("#form_asaas");
    let form6 = document.querySelector("#form_cashtime");
    let form7 = document.querySelector("#form_cashtime_pay");

    this.label0Target.classList.add("active");
    this.label1Target.classList.remove("active");
    this.label2Target.classList.remove("active");
    this.label3Target.classList.remove("active");
    this.label4Target.classList.remove("active");
    this.label5Target.classList.remove("active");
    this.label6Target.classList.remove("active");

    if (form2) {
      this.form2Target.classList.add("d-none");
    }
    if (form3) {
      this.form3Target.classList.add("d-none");
    }
    if (form4) {
      this.form4Target.classList.add("d-none");
    }
    if (form5) {
      this.form5Target.classList.add("d-none")
    }
    if (form6) {
      this.form6Target.classList.add("d-none")
    }
    if (form7) {
      this.form7Target.classList.add("d-none")
    }

    this.form1Target.classList.remove("d-none");

    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 0;
      this.documentLabelTarget.innerHTML = "CPF";
      this.documentFieldTarget.placeholder = "CPF";
      this.documentFieldTarget.classList.remove("cnpj");
      this.documentFieldTarget.classList.add("cpf");

      refreshVMasker();
    }
  }

  showAdditionalFields1() {
    let form1 = document.querySelector("#form_ciclopay");
    let form3 = document.querySelector("#form_ultragate");
    let form4 = document.querySelector("#form_pagar_me");
    let form5 = document.querySelector("#form_asaas");
    let form6 = document.querySelector("#form_cashtime");
    let form7 = document.querySelector("#form_cashtime_pay");

    this.label0Target.classList.remove("active");
    this.label1Target.classList.add("active");
    this.label2Target.classList.remove("active");
    this.label3Target.classList.remove("active");
    this.label4Target.classList.remove("active");
    this.label5Target.classList.remove("active");
    this.label6Target.classList.remove("active");

    if (form1) {
      this.form1Target.classList.add("d-none");
    }
    if (form3) {
      this.form3Target.classList.add("d-none");
    }
    if (form4) {
      this.form4Target.classList.add("d-none");
    }
    if (form5) {
      this.form5Target.classList.add("d-none")
    }
    if (form6) {
      this.form6Target.classList.add("d-none")
    }
    if (form7) {
      this.form7Target.classList.add("d-none")
    }

    this.form2Target.classList.remove("d-none");

    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 1;
      this.documentLabelTarget.innerHTML = "CNPJ";
      this.documentFieldTarget.placeholder = "CNPJ";
      this.documentFieldTarget.classList.remove("cpf");
      this.documentFieldTarget.classList.add("cnpj");
      refreshVMasker();
    }
  }
  showAdditionalFields2() {
    let form1 = document.querySelector("#form_ciclopay");
    let form2 = document.querySelector("#form_abmex");
    let form4 = document.querySelector("#form_pagar_me");
    let form5 = document.querySelector("#form_asaas");
    let form6 = document.querySelector("#form_cashtime");
    let form7 = document.querySelector("#form_cashtime_pay");

    this.label0Target.classList.remove("active");
    this.label1Target.classList.remove("active");
    this.label2Target.classList.add("active");
    this.label3Target.classList.remove("active");
    this.label4Target.classList.remove("active");
    this.label5Target.classList.remove("active");
    this.label6Target.classList.remove("active");

    if (form1) {
      this.form1Target.classList.add("d-none");
    }
    if (form2) {
      this.form2Target.classList.add("d-none");
    }
    if (form4) {
      this.form4Target.classList.add("d-none");
    }
    if (form5) {
      this.form5Target.classList.add("d-none")
    }
    if (form6) {
      this.form6Target.classList.add("d-none")
    }
    if (form7) {
      this.form7Target.classList.add("d-none")
    }

    this.form3Target.classList.remove("d-none");

    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 1;
      this.documentLabelTarget.innerHTML = "CNPJ";
      this.documentFieldTarget.placeholder = "CNPJ";
      this.documentFieldTarget.classList.remove("cpf");
      this.documentFieldTarget.classList.add("cnpj");
      refreshVMasker();
    }
  }
  showAdditionalFields3() {
    let form1 = document.querySelector("#form_ciclopay");
    let form2 = document.querySelector("#form_abmex");
    let form3 = document.querySelector("#form_ultragate");
    let form5 = document.querySelector("#form_asaas");
    let form6 = document.querySelector("#form_cashtime");
    let form7 = document.querySelector("#form_cashtime_pay");

    this.label0Target.classList.remove("active");
    this.label1Target.classList.remove("active");
    this.label2Target.classList.remove("active");
    this.label3Target.classList.add("active");
    this.label4Target.classList.remove("active");
    this.label5Target.classList.remove("active");
    this.label6Target.classList.remove("active");

    if (form1) {
      this.form1Target.classList.add("d-none");
    }
    if (form2) {
      this.form2Target.classList.add("d-none");
    }
    if (form3) {
      this.form3Target.classList.add("d-none");
    }
    if (form5) {
      this.form5Target.classList.add("d-none")
    }
    if (form6) {
      this.form6Target.classList.add("d-none")
    }
    if (form7) {
      this.form7Target.classList.add("d-none")
    }

    this.form4Target.classList.remove("d-none");

    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 1;
      this.documentLabelTarget.innerHTML = "CNPJ";
      this.documentFieldTarget.placeholder = "CNPJ";
      this.documentFieldTarget.classList.remove("cpf");
      this.documentFieldTarget.classList.add("cnpj");
      refreshVMasker();
    }
  }

  showAdditionalFields4() {
    let form1 = document.querySelector("#form_ciclopay");
    let form2 = document.querySelector("#form_abmex");
    let form3 = document.querySelector("#form_ultragate");
    let form4 = document.querySelector("#form_pagar_me");
    let form6 = document.querySelector("#form_cashtime");
    let form7 = document.querySelector("#form_cashtime_pay");

    this.label0Target.classList.remove("active");
    this.label1Target.classList.remove("active");
    this.label2Target.classList.remove("active");
    this.label3Target.classList.remove("active");
    this.label4Target.classList.add("active");
    this.label5Target.classList.remove("active");
    this.label6Target.classList.remove("active");

    if (form1) {
      this.form1Target.classList.add("d-none");
    }
    if (form2) {
      this.form2Target.classList.add("d-none");
    }
    if (form3) {
      this.form3Target.classList.add("d-none");
    }
    if (form4) {
      this.form4Target.classList.add("d-none")
    }
    if (form6) {
      this.form6Target.classList.add("d-none")
    }
    if (form7) {
      this.form6Target.classList.add("d-none")
    }

    this.form5Target.classList.remove("d-none");

    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 1;
      this.documentLabelTarget.innerHTML = "CNPJ";
      this.documentFieldTarget.placeholder = "CNPJ";
      this.documentFieldTarget.classList.remove("cpf");
      this.documentFieldTarget.classList.add("cnpj");
      refreshVMasker();
    }
  }

  showAdditionalFields5() {
    let form1 = document.querySelector("#form_ciclopay");
    let form2 = document.querySelector("#form_abmex");
    let form3 = document.querySelector("#form_ultragate");
    let form4 = document.querySelector("#form_pagar_me");
    let form5 = document.querySelector("#form_asaas");
    let form7 = document.querySelector("#form_cashtime_pay");

    this.label0Target.classList.remove("active");
    this.label1Target.classList.remove("active");
    this.label2Target.classList.remove("active");
    this.label3Target.classList.remove("active");
    this.label4Target.classList.remove("active");
    this.label5Target.classList.add("active");
    this.label6Target.classList.remove("active");

    if (form1) {
      this.form1Target.classList.add("d-none");
    }
    if (form2) {
      this.form2Target.classList.add("d-none");
    }
    if (form3) {
      this.form3Target.classList.add("d-none");
    }
    if (form4) {
      this.form4Target.classList.add("d-none")
    }
    if (form5) {
      this.form5Target.classList.add("d-none")
    }
    if (form7) {
      this.form7Target.classList.add("d-none")
    }

    this.form6Target.classList.remove("d-none");

    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 1;
      this.documentLabelTarget.innerHTML = "CNPJ";
      this.documentFieldTarget.placeholder = "CNPJ";
      this.documentFieldTarget.classList.remove("cpf");
      this.documentFieldTarget.classList.add("cnpj");
      refreshVMasker();
    }
  }
  showAdditionalFields6() {
    let form1 = document.querySelector("#form_ciclopay");
    let form2 = document.querySelector("#form_abmex");
    let form3 = document.querySelector("#form_ultragate");
    let form4 = document.querySelector("#form_pagar_me");
    let form5 = document.querySelector("#form_asaas");
    let form6 = document.querySelector("#form_cashtime");

    this.label0Target.classList.remove("active");
    this.label1Target.classList.remove("active");
    this.label2Target.classList.remove("active");
    this.label3Target.classList.remove("active");
    this.label4Target.classList.remove("active");
    this.label5Target.classList.remove("active");
    this.label6Target.classList.add("active");

    if (form1) {
      this.form1Target.classList.add("d-none");
    }
    if (form2) {
      this.form2Target.classList.add("d-none");
    }
    if (form3) {
      this.form3Target.classList.add("d-none");
    }
    if (form4) {
      this.form4Target.classList.add("d-none")
    }
    if (form5) {
      this.form5Target.classList.add("d-none")
    }
    if (form6) {
      this.form6Target.classList.add("d-none")
    }

    this.form7Target.classList.remove("d-none");

    for (let fields of this.additionalFieldsTargets) {
      fields.disabled = fields.hidden = fields.dataset.type != 1;
      this.documentLabelTarget.innerHTML = "CNPJ";
      this.documentFieldTarget.placeholder = "CNPJ";
      this.documentFieldTarget.classList.remove("cpf");
      this.documentFieldTarget.classList.add("cnpj");
      refreshVMasker();
    }
  }
}
